if ("serviceWorker" in navigator) {
    window.addEventListener("load", function() {
        function handleRegistration(registration) {
            //console.log("registration", registration);
        }

        function handleError(error) {
            console.log("error", error);
        }
        navigator.serviceWorker
            .register("/sw.js")
            .then(handleRegistration, handleError);
    });
}

var config = require("./config");

var shell = Elm.Shell.embed(document.createElement("div"), {
    mountPath: config.mountPath
});

window.ctpaint = { buildNumber: config.buildNumber };

function flags(moreFlags) {
    return Object.assign(
        {
            Client: Client,
            mountPath: config.mountPath,
            track: config.track,
            buildNumber: config.buildNumber
        },
        moreFlags
    );
}

shell.ports.toJs.subscribe(function(msg) {
    switch (msg.type) {
        case "init desktop":
            Desktop(flags({}));
            break;

        case "init paint app":
            PaintApp(flags({ init: msg.payload }));
            break;

        case "shell init error":
            config.track(msg);
            break;

        default:
            console.log("unknown shell msg type ->", msg.type);
    }
});
